import { createAction, props } from '@ngrx/store';
import { UtilityBillUploadEvent } from '../../model/utility-bill-upload-event';
import { UtilityBillSummary } from 'brain-data/model/utility-bill-summary.model';

export const resetUploadUtilityBillEventLoading = createAction('[Utility bills] reset upload utility bill event loading');

export const uploadUtilityBill = createAction(
  '[Utility bills] upload utility bill',
  props<{ month: string; year: string; utilityType: string; file?: File }>(),
);
export const uploadUtilityBillSuccess = createAction('[Utility bills] upload utility bill success', props<{ utilityBillSummary?: UtilityBillSummary }>());
export const uploadUtilityBillError = createAction('[Utility bills] upload utility bill error', props<{ error: Error }>());

export const loadUtilityBillUploadEvents = createAction(
  '[Utility Bills upload events] load utility bill upload events',
  props<{ billingYear: string; utilityTypes: string[] }>(),
);

export const loadUtilityBillAutomaticUploadEvents = createAction(
  '[Utility Bills upload events] load utility bill Automatic upload events',
  props<{ billingYear: string; utilityTypes: string[] }>(),
);

export const loadUtilityBillUploadEventsSuccess = createAction(
  '[Utility Bills upload events] load utility bill upload events success',
  props<{ utilityBillsUploadEvents: UtilityBillUploadEvent[] }>(),
);

export const loadUtilityBillUploadEventsError = createAction('[Utility Bills upload events] load utility bill upload events error', props<{ error: Error }>());

export const downloadUtilityBillFile = createAction('[Utility bills] download utility bill file', props<{ fileName: string; utilityType: string }>());

export const downloadUtilityBillFileSuccess = createAction('[Utility bills] download utility bill file success', props<{ fileName: string; blob: Blob }>());

export const downloadUtilityBillFileError = createAction('[Utility bills] download utility bill file failure', props<{ error: Error }>());
