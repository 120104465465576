import { createReducer, on } from '@ngrx/store';
import { UtilityBillSummary } from 'brain-data/model/utility-bill-summary.model';
import {
  loadUtilityBillUploadEvents,
  loadUtilityBillUploadEventsError,
  loadUtilityBillUploadEventsSuccess,
  resetUploadUtilityBillEventLoading,
  uploadUtilityBill,
  uploadUtilityBillError,
  uploadUtilityBillSuccess,
} from '../actions/utility-bills.action';
import { UtilityBillUploadEvent } from '../../model/utility-bill-upload-event';

export default interface UtilityBillsState {
  uploadingUtilityBills: boolean;
  loadingUtilityBillsUploadEvents: boolean;
  utilityBillUploadEvents: UtilityBillUploadEvent[];
  utilityBillSummary?: UtilityBillSummary;
  error?: Error;
}

export const initialState: UtilityBillsState = {
  uploadingUtilityBills: false,
  loadingUtilityBillsUploadEvents: false,
  utilityBillUploadEvents: [],
  utilityBillSummary: undefined,
  error: undefined,
};
export const reducer = createReducer(
  initialState,
  on(
    resetUploadUtilityBillEventLoading,
    (state): UtilityBillsState => ({
      ...state,
      loadingUtilityBillsUploadEvents: true,
      error: undefined,
    }),
  ),
  on(
    uploadUtilityBill,
    (state): UtilityBillsState => ({
      ...state,
      uploadingUtilityBills: true,
      utilityBillSummary: undefined,
      error: undefined,
    }),
  ),
  on(
    uploadUtilityBillSuccess,
    (state, { utilityBillSummary }): UtilityBillsState => ({
      ...state,
      error: undefined,
      utilityBillSummary,
      uploadingUtilityBills: false,
    }),
  ),
  on(
    uploadUtilityBillError,
    (state, { error }): UtilityBillsState => ({
      ...state,
      error,
      uploadingUtilityBills: false,
    }),
  ),
  on(
    loadUtilityBillUploadEvents,
    (state): UtilityBillsState => ({
      ...state,
      loadingUtilityBillsUploadEvents: true,
      uploadingUtilityBills: false,
      utilityBillSummary: undefined,
      utilityBillUploadEvents: [],
      error: undefined,
    }),
  ),
  on(
    loadUtilityBillUploadEventsSuccess,
    (state, { utilityBillsUploadEvents }): UtilityBillsState => ({
      ...state,
      utilityBillUploadEvents: utilityBillsUploadEvents,
      loadingUtilityBillsUploadEvents: false,
    }),
  ),
  on(
    loadUtilityBillUploadEventsError,
    (state): UtilityBillsState => ({
      ...state,
      loadingUtilityBillsUploadEvents: false,
    }),
  ),
);
