import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, of, switchMap } from 'rxjs';
import { UtilityBillsService } from 'brain-data/service/utility/utility-bills.service';
import {
  downloadUtilityBillFile,
  downloadUtilityBillFileError,
  downloadUtilityBillFileSuccess,
  loadUtilityBillAutomaticUploadEvents,
  loadUtilityBillUploadEvents,
  loadUtilityBillUploadEventsError,
  loadUtilityBillUploadEventsSuccess,
  uploadUtilityBill,
  uploadUtilityBillError,
  uploadUtilityBillSuccess,
} from '../actions/utility-bills.action';
import { saveAs } from 'file-saver';
import { UTILITY_BILLS_PROPS } from 'constants/utility.constant';

@Injectable()
export class UtilityBillsEffects {
  getUtilityBillUploadEventsEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadUtilityBillUploadEvents),
      switchMap(({ billingYear, utilityTypes }) =>
        this.utilityBillsService.getUtilityBillsUploadEvents(billingYear, utilityTypes).pipe(
          map(utilityBillsUploadEvents => utilityBillsUploadEvents.filter(event => event.userName !== UTILITY_BILLS_PROPS.username)),
          map(utilityBillsUploadEvents => loadUtilityBillUploadEventsSuccess({ utilityBillsUploadEvents })),
          catchError(error => of(loadUtilityBillUploadEventsError({ error }))),
        ),
      ),
    );
  });

  getUtilityBillAutomaticUploadEventsEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadUtilityBillAutomaticUploadEvents),
      switchMap(({ billingYear, utilityTypes }) =>
        this.utilityBillsService.getUtilityBillsUploadEvents(billingYear, utilityTypes).pipe(
          map(utilityBillsUploadEvents => utilityBillsUploadEvents.filter(event => event.userName === UTILITY_BILLS_PROPS.username)),
          map(filteredEvents => loadUtilityBillUploadEventsSuccess({ utilityBillsUploadEvents: filteredEvents })),
          catchError(error => of(loadUtilityBillUploadEventsError({ error }))),
        ),
      ),
    );
  });

  uploadUtilityBillExcelEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(uploadUtilityBill),
      mergeMap(({ month, year, utilityType, file }) =>
        this.utilityBillsService.uploadUtilityBill(month, year, utilityType, file).pipe(
          map(utilityBillsExcelSummary => uploadUtilityBillSuccess({ utilityBillSummary: utilityBillsExcelSummary })),
          catchError(error => of(uploadUtilityBillError({ error: error.error }))),
        ),
      ),
    );
  });

  downloadUtilityBillFileEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(downloadUtilityBillFile),
      switchMap(({ fileName, utilityType }) =>
        this.utilityBillsService.downloadUtilityBillFile(fileName, utilityType).pipe(
          map(content => downloadUtilityBillFileSuccess({ fileName, blob: new Blob([content], { type: 'application/octet-stream' }) })),
          catchError(error => of(downloadUtilityBillFileError({ error: error.error }))),
        ),
      ),
    );
  });

  downloadUtilityBillFileSuccessEffect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(downloadUtilityBillFileSuccess),
        map(({ fileName, blob }) => saveAs(blob, fileName)),
      );
    },
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private utilityBillsService: UtilityBillsService,
  ) {}
}
